.container {
  width: 140px;

  .status {
    width: fit-content;
    padding: 4px 8px;
    border-radius: 8px;

    &__draft {
      color: white;
      background: #6b7280;
    }

    &__pending {
      color: white;
      background: #f97316;
    }

    &__scheduled {
      color: white;
      background: #22c55e;
    }

    &__inProgress {
      color: white;
      background: #2563eb;
    }

    &__completed {
      color: white;
      background: #8b5cf6;
    }

    &__cancelled {
      color: white;
      background: #6b7280;
    }
  }
}
