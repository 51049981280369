.page {
  max-height: 100%;
  overflow: auto;
  padding-top: 8px;

  .content {
    max-width: 850px;
    margin: 0 auto;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .item {
    }

    .filesBlock {
      margin-top: 12px;

      .fileItem {
        padding: 4px 0;
        display: flex;
        align-items: center;

        .name {
          margin-left: 4px;
        }
      }
    }
  }

  .label {
    margin-bottom: 8px;

    .star {
      color: red;
    }
  }

  .item_fullWidth {
    width: 100%;
  }

  .date {
    display: flex;
    margin-top: 8px;

    .line {
      display: flex;
      align-items: center;
      font-size: 40px;
      margin: 0 18px;
    }
  }

  .addFile {
    border: none;
    color: green;
    margin-top: 12px;
  }

  .addUser {
    border-color: green;
    color: green;
    margin-top: 12px;
  }
}
