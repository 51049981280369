.feed {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  gap: 10px;
  padding-bottom: 100px;

  .item {
    min-width: 310px;
    max-width: 470px;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    background-color: white;

    &:hover {
      background-color: #f1f5f9;
    }

    .title {
      font-size: 18px;
      line-height: 20px;
      font-weight: 500;
      margin-top: 8px;
    }
  }
}
