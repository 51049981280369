.informationBlock {
  position: absolute;
  padding: 14px;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  background: white;
  top: 68px;
  right: 20px;
  width: 220px;
  z-index: 1;
  cursor: default;
  transform: translateY(100px);
  opacity: 0;
  visibility: hidden;
  transition:
    transform 0.2s ease-out,
    opacity 0.2s ease-out,
    visibility 0.2s;

  &__visible {
    opacity: 1;
    visibility: visible;
    transform: translate(0);
  }

  .balance {
    width: calc(100% - 20px);
    padding: 5px 10px;
    background: #facc15;
    border-radius: 6px;
    margin: 10px 0;
  }

  .logoutButton {
    width: 100%;
    border-color: red;
    color: red;
  }

  .languageSelector {
    position: static;
    margin-top: 10px;
  }
}
