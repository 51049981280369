.page {
  min-height: 100vh;

  .content {
    padding: 10px 20px 20px 20px;
    height: calc(100vh - 30px);

    &__hasHeade {
      height: calc(100vh - 30px - 70px);
    }
  }
}
