.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  height: 70px;
  border-bottom: 1px solid black;

  .logo {
    position: absolute;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 40px;
    color: black;
    text-decoration: none;
    font-size: 34px;
  }

  .userName {
    position: relative;
    cursor: pointer;
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;

    @media (hover: hover) {
      &:hover {
        background: #f3f4f6;
      }
    }

    .role {
      @media (max-width: 940px) {
        display: none;
      }
    }

    .arrow {
      margin-left: 10px;
      transition: transform 0.2s ease-out;

      &__close {
        transform: rotate(180deg);
      }
    }
  }

  .confirmButton {
    margin-right: 10px;
  }
}
