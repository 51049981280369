.videosFeed {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  gap: 10px;
  padding-bottom: 100px;

  .player {
    grid-column: 1 / -1;
    margin-bottom: 20px;
    max-height: calc(100vh - 100px);

    .video {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .playerFooter {
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 22px;
        line-height: 26px;
        font-weight: 500;
        margin-top: 8px;
      }
    }
  }

  .item {
    min-width: 310px;
    max-width: 470px;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    background-color: white;

    &:hover {
      background-color: #f1f5f9;
    }

    .title {
      font-size: 18px;
      line-height: 20px;
      font-weight: 500;
      margin-top: 8px;
    }
  }
}
