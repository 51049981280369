.page {
  width: 100%;
  height: calc(100vh - 74px - 30px - 70px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 940px) {
    overflow: auto;
  }

  .tabs {
    max-width: 700px;
  }

  .table {
    margin-top: 24px;
  }
}
