.page {
  max-height: 100%;
  overflow: auto;
  padding-top: 8px;

  .content {
    max-width: 850px;
    margin: 0 auto;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .item {
      .filesBlock {
        margin-top: 12px;

        .fileItem {
          padding: 4px 0;
          display: flex;
          align-items: center;

          .name {
            margin-left: 4px;
          }
        }
      }
    }

    .label {
      margin-bottom: 8px;

      .star {
        color: red;
      }
    }

    .button {
      width: 200px;
    }

    .dropzone {
      cursor: pointer;
      width: 100%;
      height: 100px;
      border: 1px dashed #1976d2;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #1976d2;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.75;
      text-transform: uppercase;

      &__active {
        border-style: solid;
      }
    }

    .item_fullWidth {
      width: 100%;
    }

    .date {
      display: flex;
      margin-top: 8px;

      .line {
        display: flex;
        align-items: center;
        font-size: 40px;
        margin: 0 18px;
      }
    }

    .addFile {
      border: none;
      color: green;
      margin-top: 12px;
    }

    .addUser {
      border-color: green;
      color: green;
      margin-top: 12px;
    }
  }
}
